.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-title {
  font-size: 24px;
  font-weight: normal;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 4px;
  color: #333333;
}

.question-group-title {
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 0.6rem;
  font-weight: lighter;
}

.question-group-subtitle {
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.question-group {
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 4px;
}

.question-follow {
  background-color: #eeeeee;
}

.choices {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
}

.next-btn {
  min-width: 100px;
}

.custom-select {
  background-color: #ffffff;
}

label {
  color: #555555
}

.result-subtitle {
  color: #666666;
  font-weight: lighter;
  font-size: 0.8rem;
}

.detail-desc > div{
  margin-top: 8px;
}